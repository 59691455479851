<template>
  <v-container id="regular-tables-view" fluid tag="section">
    <v-data-table :headers="headers" :items="tenantsListData" sort-by="calories" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="text-h3 font-weight-bold">站台列表</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2 font-weight-bold text-h4" v-bind="attrs" v-on="on">
                新增站台
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.name" label="名称"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.displayName" label="显示名称"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select :items="booleanGroup" item-text="name" item-value="value" v-model="editedItem.isPrepaid"
                        label="预储值"></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model.number="editedItem.balance" label="余额"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model.number="editedItem.minimumBalance" label="警示流量"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model.number="editedItem.baseServiceFee" label="服务器月费"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model.number="editedItem.serviceFeeRatio" label="流量费率(万分之)"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model.number="editedItem.billingDay"
                        :rules="[v => (v > 0 && v <= 28) || '扣帐日期1~28日']" label="基本费扣帐日期"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select :items="booleanGroup" item-text="name" item-value="value" v-model="editedItem.isEnabled"
                        label="状态"></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  关闭
                </v-btn>
                <v-btn color="blue darken-1" text @click="save">储存</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="domainDialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Domains</span>
              </v-card-title>
              <v-card-text>
                <v-row v-for="(domain, domainIndex) in domains" :key="domainIndex" style="align-items:center">
                  <v-col cols="9">
                    <v-text-field disabled v-model="domain.domain"></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-btn text color="error" small @click="deleteDomain(domain)">刪除</v-btn>
                  </v-col>
                </v-row>
                <v-row style="align-items:center">
                  <v-col cols="9">
                    <v-text-field v-model="addDomainName"></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-btn text color="blue" small @click="addDomain()">新增</v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDomainDialog">
                  取消
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="creditDialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="text-h5">信用加分</span>
              </v-card-title>
              <v-card-text>
                <v-row style="align-items:center">
                  <v-col md="6" col="12">
                    <v-text-field v-model="addBalance" label="加分"></v-text-field>
                  </v-col>
                  <v-col md="6" col="12">
                    <v-text-field v-model="memo" label="备忘录"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="creditDialog = false;">
                  取消
                </v-btn>
                <v-btn color="blue darken-1" text @click="addCredit">
                  新增
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="systemConfigDialog" max-width="90%">
            <v-card v-if="mode === 0" style="position:relative">
              <v-card-title>
                <span class="text-h4" style="font-weight:900">系统设置</span>
              </v-card-title>
              <v-card-text>
                <v-row style="align-items:center;color:#17a5ff;font-weight: 900;
                  border-bottom: 2px solid #d2d2d2">
                  <v-col cols="1">id</v-col>
                  <v-col cols="1">站台ID</v-col>
                  <v-col cols="2">显示名称</v-col>
                  <v-col cols="2">名称</v-col>
                  <v-col cols="4">值</v-col>
                  <v-col cols="2">操作</v-col>
                </v-row>
                <v-row v-for="(system, systemIndex) in systemConfigsData" :key="systemIndex"
                  style="align-items:center;border-bottom:1px solid #d2d2d2;color:#000">
                  <v-col cols="1">{{ system.id }}</v-col>
                  <v-col cols="1">{{ system.tenantId }}</v-col>
                  <v-col cols="2">
                    <span v-if="!system.isEdit">{{ system.displayName }}</span>
                    <input v-else type="text" v-model="system.displayName" />
                  </v-col>
                  <v-col cols="2">
                    <span>{{ system.name }}</span>
                  </v-col>
                  <v-col cols="4">
                    <span v-if="!system.isEdit">{{ system.value }}</span>
                    <input type="text" v-else v-model="system.value" />
                  </v-col>
                  <v-col cols="2">
                    <v-btn text fab small v-if="!system.isEdit" @click="modifySiteConfig(systemIndex)">
                      <v-icon style="color: #17a5ff">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn text fab small v-else @click="modifySiteConfigAction(systemIndex)">
                      <v-icon style="color: #17a5ff">mdi-content-save</v-icon>
                    </v-btn>
                    <v-btn text fab small v-if="system.isEdit" @click="cancelModifySiteConfig(systemIndex)">
                      <v-icon style="color: #17a5ff">mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions style="position:sticky;left:0;right:0;
                background: #fff;bottom: 0;">
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="createSystemConfigDialog">
                  新增
                </v-btn>
                <v-btn color="blue darken-1" text @click="closeSystemConfigDialog">
                  取消
                </v-btn>
              </v-card-actions>
            </v-card>
            <v-card v-else>
              <v-card-title>
                <span class="text-h5">新增系统设置</span>
              </v-card-title>
              <v-card-text>
                <v-row style="align-items:center" v-for="(form, formIndex) in editSystemItem" :key="formIndex">
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="form.name" label="名称"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="form.displayName" label="显示名称"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="form.value" label="值"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-center">
                    <v-btn fab small color="primary" @click="addSystemConfigPush()">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="createSystemConfig">
                  新增
                </v-btn>
                <v-btn color="blue darken-1" text @click="goBack">
                  返回
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogTransactionLog">
            <TransactionLogs :key="editedItem.id" :tenantId="editedItem.id"></TransactionLogs>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.balance`]="{ item }">
        {{ item.balance }}
        <v-icon small class="ml-2" @click="addCreditDialog(item)"> mdi-pencil </v-icon>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn @click="editItem(item)" small class="mr-1">
          <v-icon small class="mr-2"> mdi-pencil </v-icon>
          修改
        </v-btn>
        <v-btn @click="getDomain(item.id)" small class="mr-1">
          <v-icon small class="mr-2"> mdi-database </v-icon>
          domains
        </v-btn>
        <v-btn @click="resetRole(item)" small class="mr-1">
          <v-icon small class="mr-2"> mdi-autorenew </v-icon>
          刷新权限
        </v-btn>
        <v-btn @click="getSystemConfig(item.id)" small class="mr-1">
          <v-icon small class="mr-2"> mdi-cog </v-icon>
          系统设置
        </v-btn>
        <v-btn @click="opentransactionLogs(item)" small class="mr-1">
          <v-icon small class="mr-2"> mdi-arrow-up-bold-box-outline </v-icon>
          帐变记录
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import Vue from 'vue';
import Tenants from '@/apis/Tenants';
import loadsh from 'lodash';
import TransactionLogs from './TransactionLogs.vue';

export default {
  name: 'tenantListView',
  components: {
    TransactionLogs,
  },
  data: () => ({
    editSystemItem: [],
    defaultSystemItem: {
      tenantId: 0,
      name: '',
      displayName: '',
      value: '',
    },
    mode: 0,
    systemConfigDialog: false,
    systemConfigsData: [],
    addDomainName: '',
    domainsId: 0,
    domains: [],
    domainDialog: false,
    addBalance: 0,
    memo: '',
    creditDialog: false,
    booleanGroup: [
      { name: '开启', value: true },
      { name: '关闭', value: false }
    ],
    dialog: false,
    dialogDelete: false,
    dialogTransactionLog: false,
    editedIndex: -1,
    headers: [
      {
        text: '站台ID',
        align: 'start',
        sortable: false,
        value: 'id',
        class: 'text-h6 font-weight-bold primary--text',
      },
      {
        text: '名称',
        value: 'name',
        class: 'text-h6 font-weight-bold primary--text',
      },
      {
        text: '显示名称',
        value: 'displayName',
        class: 'text-h6 font-weight-bold primary--text',
      },
      {
        text: 'theme',
        value: 'theme',
        class: 'text-h6 font-weight-bold primary--text',
      },
      {
        text: '余额',
        value: 'balance',
        class: 'text-h6 font-weight-bold primary--text',
      },
      {
        text: '警示流量',
        value: 'minimumBalance',
        class: 'text-h6 font-weight-bold primary--text',
      },
      {
        text: '服务器月费',
        value: 'baseServiceFee',
        class: 'text-h6 font-weight-bold primary--text',
      },
      {
        text: '流量费率(万分之)',
        value: 'serviceFeeRatio',
        class: 'text-h6 font-weight-bold primary--text',
      },
      {
        text: '基本费扣帐日期',
        value: 'billingDay',
        class: 'text-h6 font-weight-bold primary--text',
      },
      {
        text: '状态',
        value: 'isEnabled',
        class: 'text-h6 font-weight-bold primary--text',
      },
      {
        text: '操作',
        value: 'actions',
        class: 'text-h6 font-weight-bold primary--text',
        sortable: false,
      },
    ],
    tenantsListData: [],
    editedId: -1,
    editedItem: {},
    defaultItem: {
      balance: 0,
      baseServiceFee: 0,
      displayName: '',
      id: -1,
      isEnabled: true,
      isPrepaid: false,
      minimumBalance: 0,
      name: '',
      serviceFeeRatio: 0,
      billingDay: 0,
      theme: '',
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? '新增站台' : '修改站台';
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    closeSystemConfigDialog() {
      this.systemConfigDialog = false;
    },
    goBack() {
      this.mode = 0;
    },
    async createSystemConfig() {
      const tenantId = this.editSystemItem[0].tenantId;
      await Tenants.createSystemConfigApi(tenantId, this.editSystemItem);
      await this.getSystemConfig(tenantId);
    },
    createSystemConfigDialog() {
      // 1 represent create and 0 represent get
      this.mode = 1;
      this.editSystemItem = [];
      this.editSystemItem.push(loadsh.cloneDeep(this.defaultSystemItem));
    },
    addSystemConfigPush() {
      this.editSystemItem.push(loadsh.cloneDeep(this.defaultSystemItem));
    },
    async getSystemConfig(id) {
      this.defaultSystemItem.tenantId = id;
      const res = await Tenants.getSystemConfigApi(id);
      this.systemConfigsData = res.data.result;
      this.mode = 0;
      this.systemConfigDialog = true;
    },
    async resetRole(item) {
      await Tenants.RefreshUserRolesApi(item.id);
      this.getTenantsList();
    },
    async addDomain() {
      const data = {
        domain: this.addDomainName,
        tenantId: this.domainsId,
      }
      await Tenants.createDomainsApi(data);
      this.addDomainName = '';
      await this.getDomain(this.domainsId);
    },
    async deleteDomain(data) {
      await Tenants.deleteDomainsApi(data);
      await this.getDomain(data.tenantId);
    },
    closeDomainDialog() {
      this.domainsId = ''
      this.addDomainName = '';
      this.domainDialog = false;
    },
    modifySiteConfig(index) {
      this.systemConfigsData[index].isEdit = true;
      this.$forceUpdate();
    },
    async modifySiteConfigAction(index) {
      if (this.systemConfigsData[index].isEdit) {
        const tenantId = this.systemConfigsData[index].tenantId;
        delete this.systemConfigsData[index].isEdit;
        const data = [];
        data.push(this.systemConfigsData[index]);
        await Tenants.createSystemConfigApi(tenantId, data);
        await this.getSystemConfig(tenantId);
      }
    },
    async cancelModifySiteConfig(index) {
      const tenantId = this.systemConfigsData[index].tenantId;
      console.log(tenantId);
      this.systemConfigsData[index].isEdit = false;
      await this.getSystemConfig(tenantId);
    },
    async getDomain(id) {
      const res = await Tenants.getDomainsApi(id);
      this.domains = res.data.result;
      this.domainsId = id;
      this.domainDialog = true;
      this.$forceUpdate();
    },
    async addCredit() {
      const data = {
        credit: Number(this.addBalance),
        memo: this.memo,
      };
      const res = await Tenants.createCreditApi(this.editedItem.id, data);
      if (res.status === 200) {
        this.closeCreditDialog();
        this.getTenantsList();
      }
    },
    closeCreditDialog() {
      this.memo = '';
      this.addBalance = 0;
      this.creditDialog = false;
    },
    addCreditDialog(item) {
      this.editedItem = loadsh.cloneDeep(this.defaultItem);
      this.editedIndex = this.tenantsListData.indexOf(item);
      this.editedItem = { ...item };
      this.creditDialog = true;
    },
    editItem(item) {
      this.editedItem = loadsh.cloneDeep(this.defaultItem);
      this.editedIndex = this.tenantsListData.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
      this.getTenantsList();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    opentransactionLogs(item) {
      this.editedItem = { ...item };
      this.dialogTransactionLog = true;
    },

    save() {
      if (this.editedItem.billingDay > 0 && this.editedItem.billingDay <= 28) {
        if (this.editedIndex > -1) {
          // update
          this.updateTenant();
        } else {
          // create
          this.createTenant();
        }
        this.close();
      } else {
        Vue.$toast.open({
          message: `基本费扣帐日期有误`,
          type: 'error',
          position: 'top-right',
        });
      }
    },
    async updateTenant() {
      await Tenants.updateTenantsApi(this.editedItem.id, this.editedItem);
    },
    async createTenant() {
      const res = await Tenants.createTenantsApi(this.editedItem);
      if (res.status === 200) {
        this.initTenant(res.data.result);
        this.getTenantsList();
      }
    },
    async getTenantsList() {
      const res = await Tenants.getTenantsApi(false);
      this.tenantsListData = res.data.result;
    },
    async initTenant(data) {
      const res = await Tenants.tenantInitApi(data.id);
    }
  },
  created() {
    this.getTenantsList();
  },
};
</script>
<style scoped lang="sass">
input
  background: none
  outline: none
  border: none
  border-bottom: 1px solid #999
  width: 90%
input:focus
  border-bottom: 1px solid #00cd00
</style>
